<template>
  <div
    class="page"
    :style="{
      backgroundColor: active === 3 ? 'transparent' : '',
      paddingTop: active !== 3 ? '32px' : '',
    }"
  >
    <div class="page-tit">
      {{ $t("LiteFill.企业碳排放速算工具") }}
    </div>
    <div class="select-box" v-if="active === 1">
      <el-form :inline="true" :model="formInline" class="selectTime">
        <el-form-item :label="$t('LiteFill.年份') + ':'">
          <el-date-picker
            :clearable="false"
            v-model="formInline.year"
            type="year"
            @change="getData"
            :picker-options="pickerOptions"
            :placeholder="$t('global.请选择')"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('Login.industryId') + ':'">
          <el-select v-model="formInline.industryId" @change="getData">
            <el-option
              v-for="item in industryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="select-content" v-if="deptList.length > 0">
        <el-tabs
          @tab-click="tabsClick"
          :tab-position="'left'"
          v-model="formInline.deptId"
        >
          <el-tab-pane
            v-for="(item, index) in deptList"
            :key="item.id"
            :name="item.id"
          >
            <el-badge
              slot="label"
              :hidden="getSelectNum(index) === 0"
              :value="getSelectNum(index)"
              :max="99"
            >
              <span> {{ item.name }}</span>
            </el-badge>
          </el-tab-pane>
        </el-tabs>
        <div class="select-emission" v-if="emissionList.length > 0">
          <div
            class="myCard"
            v-for="item in emissionList"
            :key="item.id"
            @click="selectEmission(formInline.deptId, item.id)"
            :class="{ isAct: item.isAct }"
          >
            <div class="icon"></div>
            <div class="name">{{ item.name }}</div>
            <div class="select"></div>
          </div>
        </div>
        <el-empty
          v-else
          style="flex: 1"
          :description="$t('MarkPrice.index.暂无数据')"
        ></el-empty>
      </div>
      <el-empty v-else :description="$t('MarkPrice.index.暂无数据')"></el-empty>
    </div>
    <div class="currentYear" v-if="active === 2">
      {{ $t("LiteFill.年份") }}: {{ $moment(formInline.year).format("YYYY") }}
    </div>
    <div class="currentYear" v-if="active === 3">
      {{ companyName }}: {{ $moment(formInline.year).format("YYYY") }}
      {{ $t("LiteFill.年") }}
    </div>
    <div class="fill-table" v-if="active === 2">
      <el-table
        :data="[...tableData]"
        :span-method="objectSpanMethod"
        :height="autoTableHeight"
        border
        style="width: 100%; margin-top: 20px"
      >
        <el-table-column
          align="center"
          prop="departmentName"
          :label="$t('LiteFill.部门')"
          width="180"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="emissionsName"
          :label="$t('LiteFill.排放源名称')"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="fillPrompt"
          :label="$t('LiteFill.填报项')"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="activityLevel"
          :label="$t('LiteFill.活动水平')"
        >
          <template slot-scope="{ row }">
            <el-input
              type="number"
              v-model.trim="row.activityLevel"
              :placeholder="$t('global.请输入')"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="activityLevelName"
          :label="$t('LiteFill.单位')"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="report-form" v-if="active === 3">
      <div class="content">
        <div class="left">
          <el-table
            :data="threeTableData"
            :span-method="objectSpanMethodTwo"
            border
            :height="500"
          >
            <el-table-column align="center" prop="deptName" :label="'部门'">
            </el-table-column>
            <el-table-column
              align="center"
              prop="emissionsName"
              :label="'排放源名称'"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="emissionsEquivalent"
              :label="'温室气体排放量（吨二氧化碳当量）'"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="carbonReduction"
              :label="'减碳潜力预估'"
            >
              <template slot-scope="scope">
                <el-progress
                  :percentage="scope.row.carbonReduction || 0"
                ></el-progress>
              </template>
            </el-table-column>
          </el-table>
          <div class="total-box">
            <div>
              年总排放量: <span>{{ tableTotal.emissionTotal }}</span
              >吨
            </div>
            <div>
              年减排潜力: <span>{{ tableTotal.zjplTotal }}</span
              >吨
            </div>
            <div>
              年排放成本: <span>{{ tableTotal.pfpriceTotal }}</span
              >万元
            </div>
            <div>
              年减排经济权益: <span>{{ tableTotal.jfpriceTotal }}</span
              >万元
            </div>
          </div>
        </div>
        <div class="right">
          <div class="barChart" ref="barRef"></div>
          <div class="pieChart" ref="pieRef"></div>
        </div>
      </div>
      <div class="tooltip">
        {{ tooltipInfo }}
        <div>
          <a
            href="http://www.carbonebook.com/index.php/zh/features"
            target="_blank"
          >
            http://www.carbonebook.com/index.php/zh/features
          </a>
        </div>
      </div>
    </div>
    <div class="footer-btn-box">
      <template v-if="active === 3">
        <el-button
          size="medium"
          type="info"
          @click="resetInput"
          plain
          :delay="false"
          >{{ "重新计算" }}</el-button
        >
        <el-button size="medium" type="primary" @click="exportFile">{{
          "报告导出"
        }}</el-button>
      </template>
      <template v-if="active === 2">
        <el-button
          size="medium"
          type="info"
          @click="active--"
          plain
          :delay="false"
          >{{ $t("LiteFill.上一步") }}</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="submitFill"
          :delay="false"
          >{{ $t("global.submit") }}</el-button
        >
      </template>
      <template v-if="active === 1 || active === 0">
        <el-button
          size="medium"
          type="info"
          v-if="active !== 0 && isShowFill"
          @click="active--"
          plain
          :delay="false"
          >{{ $t("LiteFill.上一步") }}</el-button
        >
        <el-button
          size="medium"
          type="primary"
          @click="nextStep()"
          :delay="false"
          >{{ $t("LiteFill.下一步") }}</el-button
        >
      </template>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import md5 from 'js-md5'
import tool from '@/tool'
export default {
  data () {
    return {
      // 公共
      active: 1,
      formInline: {
        year: null,
        deptId: '',
        industryId: ''
      },
      // 第一步
      ruleForm: {
      },
      industryList: [],
      tempId: null,
      tempPhone: null,
      // 第二步
      pickerOptions: {
        cellClassName: (date) => {
          // return this.yearList.includes(new Date(date).getFullYear().toString()) ? 'year-fill-class' : ''
          // console.log(this.yearList, date)
          // console.log(this.yearList.includes(new Date(date).getFullYear().toString()))
        }
      },
      deptList: [],
      emissionList: [],
      logEmissionsList: [],
      // 第三步
      tableMatrix: [],
      autoTableHeight: 800,
      tableData: [],
      // 第四步
      threeTableData: [],
      tooltipInfo: '',
      tableTotal: {},
      threeTableMatrix: {},
      isShowFill: true,
      companyName: '',
      pieCart: {},
      barCart: {}
    }
  },
  watch: {
  },
  created () {
    this.getIndustry()
    this.getUserInfo()
  },
  mounted () {
    var a = document.querySelector('.u-body').getBoundingClientRect().height
    this.autoTableHeight = a - 280
  },
  methods: {
    submitFill () {
      if (!this.tableData.every(item => item.activityLevel && item.activityLevel.toString())) return this.$message.error(this.$t('LiteFill.请完善活动水平'))
      if (!this.tableData.every(item => item.activityLevel > 0)) return this.$message.error(this.$t('LiteFill.不可小于0'))
      if (this.tableData.some(item => item.activityLevel > 999999999)) return this.$message.error(this.$t('LiteFill.数值过大'))
      this.$confirm(this.$t('LiteFill.submitMsg'), { type: 'warning' }).then(res => {
        this.nextStep()
      })
    },
    getUserInfo () {
      if (this.$store.state.user.liteIsFirstLogin === 0) {
        api.lite.queryLiteEmissionsFinished().then(res => {
          if (res.data.length > 0) {
            this.formInline.year = res.data[0].year
            this.active = 3
            this.initThree()
          }
        })
      }
    },
    async nextStep () {
      if (this.active === 1) {
        if (!this.formInline.year || !this.formInline.industryId) return this.$message.error(this.$t('LiteFill.请选择年份和行业'))
        const { emissionsIds } = this.getSelectAll()
        if (emissionsIds.length < 1) return this.$message.error(this.$t('LiteFill.您还未选择排放源'))
        this.getFillData()
      }
      if (this.active === 2) {
        this.saveTableDataData(this.tableData, this.initThree)
      }
      this.active++
    },
    // 第二步
    getData () {
      if (!this.formInline.year || !this.formInline.industryId) return
      this.queryDeptList(() => {
        this.getLog()
      })
    },
    getLog () {
      this.logEmissionsList = []
      api.lite.queryAnnualLog({ year: this.$moment(this.formInline.year).format('YYYY'), inquiryId: this.formInline.industryId }).then(res => {
        if (res.data) {
          res.data.departmentIds.forEach(departmentId => {
            this.queryDeptEmissionList(departmentId)
          })
          this.logEmissionsList = res.data.emissionsList.map(item => item.id)
          res.data.emissionsList.forEach((item) => {
            this.selectEmissionOne(item.departmentId, item.id)
          })
          this.flagIsActive()
        }
      })
    },
    // 只选中不取消
    selectEmissionOne (deptId, currentId) {
      const deptList = [...this.deptList]
      const currentDeptIndex = deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        if (deptList[currentDeptIndex].emissionId && !deptList[currentDeptIndex].emissionId.includes(currentId)) {
          deptList[currentDeptIndex].emissionId.push(currentId)
        } else {
          deptList[currentDeptIndex].emissionId = [currentId]
        }
      }
      this.deptList = deptList
    },
    // 行业
    getIndustry () {
      api.lite.queryIndustryList().then(res => {
        this.industryList = res.data
      })
    },
    // 部门
    queryDeptList (callback = () => { }) {
      api.lite.queryDeptList({ industryId: this.formInline.industryId }).then(res => {
        this.deptList = res.data
        callback()
      })
    },
    tabsClick ({ name }) {
      this.queryEmissionList(name)
    },
    // 不切换tab,隐式查询部门下的排放源
    queryDeptEmissionList (deptId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex === -1) return
      api.lite.deptQueryEmission({ departmetIds: [deptId], industryId: this.formInline.industryId }).then(res => {
        this.deptList[currentDeptIndex].data = res.data.map(item => ({ ...item, isAct: this.logEmissionsList.includes(item.id) }))
      })
    },
    // 根据部门和行业id查排放源
    queryEmissionList (deptId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1 && this.deptList[currentDeptIndex].data) {
        return this.getEmissionList(deptId)
      }
      api.lite.deptQueryEmission({ departmetIds: [deptId], industryId: this.formInline.industryId }).then(res => {
        if (currentDeptIndex !== -1) {
          this.deptList[currentDeptIndex].data = res.data.map(item => ({ ...item, isAct: this.logEmissionsList.includes(item.id) }))
          this.getEmissionList(deptId)
        }
      })
    },
    // 显示当前部门的排放源列表
    getEmissionList (deptId) {
      this.emissionList = []
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        this.emissionList = this.deptList[currentDeptIndex].data
      } else {
        this.emissionList = []
      }
    },
    // 判断排放源是否选中
    flagIsActive () {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === this.formInline.deptId)
      if (currentDeptIndex === -1 || !this.deptList[currentDeptIndex].emissionId) return false
      const currentDept = this.deptList[currentDeptIndex]
      const resList = currentDept.data.map(item => ({ ...item, isAct: currentDept.emissionId.includes(item.id) }))
      this.deptList[currentDeptIndex].data = resList
      this.getEmissionList(this.formInline.deptId)
    },
    // 选中和取消选中
    selectEmission (deptId, currentId) {
      const currentDeptIndex = this.deptList.findIndex(item => item.id === deptId)
      if (currentDeptIndex !== -1) {
        if (this.deptList[currentDeptIndex].emissionId) {
          const currentSelectList = this.deptList[currentDeptIndex].emissionId
          if (currentSelectList.includes(currentId)) {
            const temp = currentSelectList.findIndex(item => item === currentId)
            this.deptList[currentDeptIndex].emissionId.splice(temp, 1)
          } else {
            this.deptList[currentDeptIndex].emissionId.push(currentId)
          }
        } else {
          this.deptList[currentDeptIndex].emissionId = [currentId]
        }
      }
      this.flagIsActive(currentId)
    },
    getSelectNum (index) {
      const item = this.deptList[index]
      if (!item.emissionId) return 0
      return item.emissionId.length
    },
    // 第三步
    getSelectAll () {
      const departmetIds = []
      const emissionsIds = this.deptList.map(item => {
        if (item.emissionId && item.emissionId.length > 0) {
          departmetIds.push(item.id)
          return item.emissionId
        } else {
          return []
        }
      })
      return {
        emissionsIds: emissionsIds.flat(Infinity),
        departmetIds
      }
    },
    getFillData () {
      const { emissionsIds, departmetIds } = this.getSelectAll()
      api.lite.queryTableData({
        emissionsIds: [...new Set(emissionsIds)],
        departmetIds,
        year: this.$moment(this.formInline.year).format('YYYY')
      }).then(res => {
        const matrixIndex = { // 手动定位
          departmentName: 0
        }
        this.tableData = res.data
        this.tableMatrix = tool.tableMatrix.matrix(matrixIndex, this.tableData)
      })
    },
    saveTableDataData (data, callback = () => { }) {
      api.lite.saveTableData({ list: data, industryId: this.formInline.industryId }).then(res => {
        callback()
      })
    },
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (this.tableMatrix[columnIndex]) {
        return this.tableMatrix[columnIndex][rowIndex]
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    objectSpanMethodTwo ({ row, column, rowIndex, columnIndex }) {
      if (this.threeTableMatrix[columnIndex]) {
        return this.threeTableMatrix[columnIndex][rowIndex]
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    // 第四步
    initThree () {
      api.lite.queryThreeData({ industryId: this.formInline.industryId, year: this.formInline.year ? this.$moment(this.formInline.year).format('YYYY') : '' }).then(res => {
        const { departTableMap, rangeMap, departMap, info, companyName } = res.data
        this.companyName = companyName
        this.tooltipInfo = info
        this.tableTotal = departTableMap
        const temp = departTableMap.departTableMapList.map(item => {
          return item.emissionsTableMapList.map(node => ({ ...node, deptName: item.deptName }))
        }).flat(Infinity)
        this.threeTableMatrix = tool.tableMatrix.matrix({ deptName: 0 }, temp)
        this.threeTableData = temp
        this.$nextTick(() => {
          this.initBar(rangeMap.rangeMapList)
          this.initPie(departMap.departMapList)
        })
      })
    },
    resetInput () {
      this.active = 1
      this.formInline.year = null
      this.formInline.industryId = null
      this.deptList = []
      this.emissionList = []
    },
    canvasToBase64 (target) {
      // const canvas = target.querySelector('canvas')
      // return canvas.toDataURL("image/jpeg", 1).replace('data:image/jpeg;base64,', '')
      return target.getDataURL().replace('data:image/png;base64,', '')
    },
    initBar (data) {
      const myCart = this.echarts.init(this.$refs.barRef)
      myCart.setOption(
        {
          title: {
            text: '各范围排放量（吨）',
            top: 16,
            left: 24
          },
          tooltip: {},
          xAxis: {
            type: 'category',
            data: data.map(item => item.scopeName),
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#c8c8c8'
              }
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              lineStyle: {
                type: 'dashed'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#c8c8c8'
              }
            }
          },
          series: [
            {
              data: data.map((item, index) => ({
                value: item.total,
                itemStyle: {
                  color: ['#73dede',
                    '#ffae26',
                    '#ff7151',
                    '#3770E2',
                    '#C037E2',
                    '#71DCDC',
                    '#71E75F',
                    '#32C88A',
                    '#E1DC5E',
                    '#FFAE26',
                    '#7968E3',
                    '#FE6F50',
                    '#E75D5D'][index]
                }
              })),
              barWidth: 50,
              type: 'bar',
              label: {
                show: true,
                position: 'top'
              }
            }
          ]
        }
      )
      this.barCart = myCart
    },
    initPie (data) {
      const myCart = this.echarts.init(this.$refs.pieRef)
      myCart.setOption({
        title: {
          text: '部门排放分布',
          top: 16,
          left: 24
        },
        grid: {
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          top: 'center',
          right: '60',
          icon: 'circle'
        },
        series: [
          {
            type: 'pie',
            radius: '50%',
            radius: ['40%', '60%'],
            center: ['40%', '60%'],
            color: ['#73DEDE', '#FF7151', '#FFAE26'],
            data: data.map(item => ({ name: item.deptName, value: item.total })),
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80
            },
            label: {
              formatter: '排放量:  {c}吨\n占比:  {d}%',
              color: '#666666',
              minMargin: 5,
              lineHeight: 15
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < chart1Obj.getWidth() / 2
              const points = params.labelLinePoints
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width
              return {
                labelLinePoints: points
              }
            }
            // emphasis: {
            //   itemStyle: {
            //     shadowBlur: 10,
            //     shadowOffsetX: 0,
            //     shadowColor: 'rgba(0, 0, 0, 0.5)'
            //   }
            // }
          }
        ]
      })
      this.pieCart = myCart
    },
    exportFile () {
      this.$prompt('报告将通过邮箱发送给您，请确保邮箱地址正确', '报告导出', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/,
        inputErrorMessage: '邮箱格式不正确',
        inputValue: this.ruleForm.loginName,
        closeOnPressEscape: false,
        closeOnClickModal: false
      }).then(({ value }) => {
        api.lite.exportFile({
          year: this.$moment(this.formInline.year).format('YYYY'),
          email: value,
          pirImage: this.canvasToBase64(this.pieCart),
          barImage: this.canvasToBase64(this.barCart)
        }).then(res => {
          this.$message.success(this.$t('global.handleOk'))
        })
      })
    },
    // 第一步
    submitForm (formName, callback = () => { }) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checkList.length < 1) return this.$message.error('请选择企业碳管理期望')
          this.submitPopLoading = true
          api.lite.updateLiteUser({
            ...this.ruleForm,
            userName: this.ruleForm.userName.trim(),
            id: this.tempId,
            productInfo: this.ruleForm.productInfo.trim(),
            phone: this.tempPhone,
            expect: this.checkList.length > 0 ? this.checkList.join() + ',' : ''
          }).then((res) => {
            this.submitPopLoading = false
            this.queryDeptList()
            callback && callback()
          }).catch((err) => {
            this.submitPopLoading = false
          })
        }
      })
    }
  }
}
</script>

<style>
.u-body {
  background-color: #f7f8f7;
}
</style>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
/deep/.el-progress__text {
  color: #41b988;
}
.page {
  width: 1440px;
  position: relative;
  background-color: #fff;
  min-height: calc(100% - 64px);
  padding: 32px;
  padding-bottom: 100px;
  margin: 0 auto;
  padding-top: 0;
  .page-tit {
    text-align: center;
    margin: 28px 0;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #09a666;
  }
  .currentYear {
    font-size: 20px;
    margin-bottom: 24px;
  }
  .select-box {
    /deep/ .select-content {
      display: flex;
      height: 500px;
      .el-tabs {
        height: 100%;
        flex-shrink: 0;
        .el-tabs__content {
          display: none;
        }
        .el-badge__content {
          height: 14px;
          line-height: 14px;
        }
        .el-tabs__nav-scroll {
          padding-top: 20px;
        }
        .el-tabs__active-bar {
          width: 4px;
          border-radius: 2px 2px 2px 2px;
        }
        .el-tabs__item {
          font-size: 16px;
          transition: all 0.2s;
          padding-right: 30px;
          &.is-active {
            /* font-size: 20px; */
            transform: scale(1.2);
          }
        }
      }
      .select-emission {
        flex: 1;
        max-height: 100%;
        height: max-content;
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        .myCard {
          margin-left: 24px;
          margin-bottom: 24px;
          position: relative;
          width: 217px;
          height: 85px;
          background: #ffffff;
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #d9d9d9;
          cursor: pointer;
          transition: transform 0.3s;
          padding: 0 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background: #09a666;
          }
          &.isAct,
          &:hover {
            border: 1px solid #09a666;
            background: #f0f9f5;
          }
          .icon {
            width: 48px;
            height: 48px;
            background-color: red;
            margin-right: 21px;
            background: url("./../../assets/img/lite/emission-icon.png")
              no-repeat center/95% 100%;
            flex-shrink: 0;
          }
          .name {
            flex: 1;
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #000000;
            user-select: none;
          }
          .select {
            width: 22px;
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 50%;
          }
          &.isAct {
            .select {
              background: url("./../../assets/img/lite/active-icon.png")
                no-repeat center/cover !important;
            }
          }
        }
      }
    }
  }
  /deep/ .selectTime {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner,
    .el-form-item__content {
      width: 118px;
    }
    .el-form-item + .el-form-item {
      margin-left: 32px;
    }
  }
}
.report-form {
  .tooltip {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFangSC-Medium-, PingFangSC-Medium;
    color: #ff4d4f;
    line-height: 22px;
  }
  .content {
    display: flex;
    .left {
      position: relative;
      padding: 33px;
      margin-right: 22px;
      width: 848px;
      height: 600px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      .total-box {
        position: absolute;
        bottom: 20px;
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.65);
        & > div {
          margin-top: 5px;
          &:not(:last-child) {
            margin-right: 33px;
          }
          span {
            font-weight: 600;
            margin: 0 5px;
            color: #09a666;
          }
        }
      }
    }
    .right {
      flex: 1;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      & > div {
        height: 50%;
        background-color: #fff;
      }
      .barChart {
        margin-bottom: 20px;
      }
      /*
      .pieChart {
        height: 330px;
      } */
    }
  }
}

/deep/.register-ruleForm {
  width: 1000px;
  margin: 0 auto;
  .myRow {
    padding-left: 57px;
  }
}
/deep/.survey-box {
  .el-row {
    margin-bottom: 24px;
    .el-col {
      &:first-child {
        margin-bottom: 0;
      }
    }
  }
  .survey-tit {
    margin-top: 38px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    &::before {
      content: "*";
      display: inline-block;
      color: #f56c6c;
      margin-right: 4px;
    }
  }
  .el-input {
    width: 186px;
    .el-input__inner {
      font-size: 14px;
      height: 20px;
      border: none;
      border-bottom: 1px solid #dcdcdc;
    }
  }
}
.myTitle {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin-bottom: 24px;
  &::before {
    content: "";
    display: inline-block;
    position: relative;
    top: 3px;
    margin-right: 8px;
    width: 4px;
    height: 22px;
    background: #09a666;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
  }
}
.footer-btn-box {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translateX(-50%);
  .reset-btn {
    width: 100px !important;
  }
  .el-button {
    width: 128px;
  }
}
canvas {
  background-color: #fff;
}
</style>
